.otp-group {
  display: flex;
  max-width: 240px;
  column-gap: 12px;
}

.otp-input {
  width: 100%;
  height: 48px;
  padding: 0;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
}
