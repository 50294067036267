@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.reset-style ol {
	list-style: unset !important;
	padding-left: 40px !important;
	margin-top: 16px !important;
	margin-bottom: 16px !important;
}

/* 300 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-Light.ttf");
	font-weight: 300;
}

/* 400 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-Regular.ttf");
}

/* 500 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-Medium.ttf");
	font-weight: 500;
}

/* 600 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-SemiBold.ttf");
	font-weight: 600;
}

/* 700 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-Bold.ttf");
	font-weight: bold;
}

/* 800 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-ExtraBold.ttf");
	font-weight: 800;
}

/* 900 */
@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins/Poppins-Black.ttf");
	font-weight: 900;
}

* {
	font-family: "Poppins", "Inter", sans-serif;
}
